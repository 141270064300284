@import 'antd/dist/antd.less';
@primary-color: #077eae ;
body {
  background-color: rgba(0,0,0,0.0) ;
  // position: relative;
  // color: white;
  height: 100vh;
  font-family: Arial, Helvetica, Helvetica Neue, serif;
  overflow-y: hidden;
}

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  color: #fff;
}

p {
  font-size: 24px;
}

li {
  list-style: none;
}

a {
  color: white;
  opacity: 0.75;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}


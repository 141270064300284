@blue: #0AE;
@wht: #FFF;

:root {
  @blue: #0AE;
  @wht: #FFF
}

.container{
  overflow: hidden;
  background: @blue;
  text-align: center;
  line-height: 100vh;
}

.img{
  margin-left: -70px
}

.group{
  bottom: 300px;
  position: absolute;
  display: inline-block;
  line-height: 16px;
}

.bigSqr{
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  overflow: hidden;
  transform-origin: bottom left;
  animation: bigSqrShrink 1s linear infinite;
}

.square{
  position: absolute;
  width: 20px;
  height: 20px;
  background: @wht;
}

.first{
  left: 0px;
  top: 20px;
}


.second{
  left: 20px;
  top: 20px;
  animation: drop2 1s linear infinite;
}

.third{
  left: 0px;
  top: 0px;
  animation: drop3 1s linear infinite;
}

.fourth{
  left: 20px;
  top: 0px;
  animation: drop4 1s linear infinite;
}

.text{

  line-height: 16px;
  font-family: "Open Sans","Roboto",Arial , sans-serif;
  font-weight: 400;
  color: @wht;
  display: block;
  margin: 10px auto;
  padding: 3px;
}

@keyframes bigSqrShrink{
  0% {
    transform: scale(1);
  }
  90% {
    transform: scale(1);
  }
  100%{
    transform: scale(0.5);
  }
}

@keyframes drop2{

  0% {
    transform: translateY(-50px);
  }
  25%{
    transform: translate(0);
  }
  100%{
    transform: translate(0);
  }
}
@keyframes drop3{

  0%{
    transform: translateY(-50px);
  }
  50%{
    transform: translate(0);
  }
  100%{
    transform: translate(0);
  }
}

@keyframes drop4{
  0%{
    transform: translateY(-50px);
  }

  75%{

    transform: translate(0);
  }
  100%{
    transform: translate(0);
  }
}


